import * as React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { ContextProviderComponent } from "../Context";
import { motion } from "framer-motion";

const pageStyles = {
  background: "#ECECEC",
  minHeight: "100vh",
  margin: 0,
  padding: 0,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

const Layout = ({ children, pageContext }) => {
  if (pageContext.layout === "heart") {
    return (
      <>
        <ContextProviderComponent>
          <motion.div
            initial={{
              opacity: 0,
              y: -50,
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            exit={{
              opacity: 0,
              y: 50,
            }}
            transition={{
              type: "spring",
              mass: 0.35,
              stiffness: 75,
              duration: 0.3,
            }}
            style={pageStyles}
          >
            {children}
          </motion.div>
        </ContextProviderComponent>
      </>
    );
  }
  return (
    <>
      <ContextProviderComponent>
        <Header />
        <motion.div
          initial={{
            opacity: 0,
            y: -50,
          }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          exit={{
            opacity: 0,
            y: 50,
          }}
          transition={{
            type: "spring",
            mass: 0.35,
            stiffness: 75,
            duration: 0.3,
          }}
          style={pageStyles}
        >
          {children}
        </motion.div>
        <Footer />
      </ContextProviderComponent>
    </>
  );
};

export default Layout;
