import * as React from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export default function Header() {
  const [open, setOpen] = useState(false);

  const menuItem = { fontSize: "2rem", padding: "1rem", cursor: "pointer" };

  const textMotion = {
    rest: {
      x: -30,
    },
    hover: {
      x: -8,
    },
  };

  const boxMotion = {
    rest: open ? { x: 50 } : { x: 0 },
    hover: { x: 70 },
  };

  return (
    <>
      <motion.nav
        style={{
          width: 120,
          height: 50,
          backgroundColor: "black",
          position: "fixed",
          top: 10,
          left: -95,
          zIndex: 100,
          cursor: "pointer",
        }}
        variants={boxMotion}
        whileHover="hover"
        animate="rest"
        initial={{
          x: -95,
        }}
        exit={{
          x: -10,
        }}
        transition={{
          type: "spring",
          mass: 0.5,
          stiffness: 200,
          duration: 0.3,
        }}
        onTap={() => {
          setOpen(!open);
        }}
      >
        <div
          style={{
            width: 95,
            height: 30,
            backgroundColor: "white",
            margin: 10,
            marginLeft: 15,
            zIndex: 101,
            color: "black",
            fontFamily: "Fira Mono",
            fontSize: "1.9rem",
            cursor: "pointer",
          }}
        >
          <motion.span
            style={{
              display: "block",
              fontWeight: 400,
              textAlign: "right",
              cursor: "pointer",
            }}
            variants={textMotion}
            transition={{
              type: "spring",
              mass: 0.5,
              stiffness: 200,
              duration: 0.3,
            }}
          >
            {open ? "X" : "MENU"}
          </motion.span>
        </div>
      </motion.nav>
      {open ? (
        <motion.div
          style={{
            width: "100vw",
            height: "100vh",
            background: "black",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 99,
            margin: 0,
            padding: 0,
          }}
          initial={{
            opacity: 0,
            x: -3000,
          }}
          animate={{
            opacity: 1,
            x: 0,
          }}
          exit={{
            opacity: 0,
            x: -3000,
          }}
          transition={{
            type: "spring",
            mass: 0.5,
            stiffness: 100,
            duration: 0.3,
          }}
        >
          <ul
            style={{
              display: "flex",
              flexDirection: "column",
              color: "white",
              textDecoration: "none",
              listStyleType: "none",
              textAlign: "center",
              fontFamily: "Fira Mono",
              fontSize: "1.9rem",
              justifyContent: "center",
              justifyItems: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <li style={menuItem}>
              <Link
                onClick={() => {
                  setOpen(false);
                }}
                to="/"
              >
                Home
              </Link>
            </li>
            <li style={menuItem}>
              <AnchorLink
                style={{ color: "white" }}
                to="/#about"
                title="About"
                onAnchorLinkClick={() => {
                  setOpen(false);
                }}
              >
                About
              </AnchorLink>
            </li>
            <li style={menuItem}>
              <Link
                onClick={() => {
                  setOpen(false);
                }}
                to="/takepart/apply"
              >
                Apply
              </Link>
            </li>
            <li style={menuItem}>
              <AnchorLink
                style={{ color: "green" }}
                to="/#hearts"
                title="Archive"
                onAnchorLinkClick={() => {
                  setOpen(false);
                }}
              >
                <span style={{ color: "white" }}>Archive</span>
              </AnchorLink>
            </li>
          </ul>
        </motion.div>
      ) : null}
    </>
  );
}
